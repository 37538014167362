<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="alarmNoticeRecords"></gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search
					keyWord="通知时间区间"
					width="520px"
					type="date"
					datePickerType="datetimerange"
					:search.sync="form.time"
				></gc-custom-search>
				<gc-custom-search
					keyWord="推送渠道"
					width="300px"
					type="select"
					:search.sync="form.notifyType"
					:searchOption="notifyType"
					:searchOptionKey="{ label: 'name', value: 'defaultValue' }"
					:needAllForSearch="true"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button type="primary" @click="getList(1, apiGetAlarmNoticeList, 'create')" v-click-blur>
						查 询
					</el-button>
					<el-button @click="reset(apiGetAlarmNoticeList, 'create')" v-click-blur>重 置</el-button>
				</div>
				<gc-column-setting
					:storageKey="`localcolumn-alarm-notice-records`"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					:columns="columnList"
					:table-data="tableData"
					:border="true"
					:showPage="true"
					:total="page.total"
					:page-size="page.size"
					:currentPage="page.current"
					@current-page-change="
						currentPage => {
							currentPageChange(currentPage, apiGetAlarmNoticeList, 'create')
						}
					"
					@size-change="
						size => {
							page.size = size
							currentPageChange(1, apiGetAlarmNoticeList, 'create')
						}
					"
				></gc-table>
			</div>
		</div>
	</div>
</template>

<script>
import { apiGetAlarmNoticeList } from '@/apis/alarm.api.js'
import common from '../mixins/common.js'
import { nameTrans } from '@/utils/index.js'

export default {
	name: 'alarmNoticeRecords',
	mixins: [common],
	data() {
		return {
			form: {
				time: null,
				notifyType: null, //推送渠道
			}, //筛选条件
			columns: [
				{
					key: 'createTime',
					name: '推送时间',
					width: 170,
				},
				{
					key: 'sendStatus',
					name: '推送状态',
					width: 170,
					render: (h, row) => {
						return h('span', {}, nameTrans(row.sendStatus, this.sendStatusOptions))
					},
				},
				{
					key: 'sendContent',
					name: '内容',
				},
				{
					key: 'receiveName',
					name: '接收人',
					width: 170,
				},
				{
					key: 'notifyType',
					name: '推送渠道',
					width: 170,
					render: (h, row) => {
						return h('span', {}, nameTrans(row.notifyType, this.notifyType))
					},
				},
			],
		}
	},
	computed: {
		notifyType() {
			return this.$store.getters.dataList.notifyType || []
		},
		sendStatusOptions() {
			return this.$store.getters.dataList.sendStatus || []
		},
		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},
	mounted() {
		this.getList(1, apiGetAlarmNoticeList, 'create')
	},
	methods: {
		apiGetAlarmNoticeList,
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
</style>
